import React, { useContext } from "react";
import "./nav_bar.css";
import { Box, Button, Modal, Typography, Tabs, Tab, Link, Snackbar, Alert } from "@mui/material";
import SignIn from "./sign_in";
import SignUp from "./sign_up";
import { AuthContext, AuthDispatchContext } from "../state/auth_context";
import { findBackend } from "../util/find_backend";
import banners from "../banners/banners.json";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface AuthTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function AuthTabPanel(props: AuthTabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function NavBar(): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(true);
  const authContext = useContext(AuthContext);
  const authDispatch = useContext(AuthDispatchContext);

  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setTabIndex(newValue);
  };
  const handleLoginSuccess = (username: string): void => {
    handleClose();
    fetch(`${findBackend()}/profile`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.status === 200) {
          const respJSon = await response.json();
          if (authDispatch != null) {
            authDispatch({
              type: "SET_LOGIN",
              payload: username,
            });
            authDispatch({
              type: "SET_ASSOCIATIONS",
              payload: respJSon.associations,
            });
          }
        } else {
          console.log(await response.text());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAgeRestrictionAck = (): void => {
    if (authDispatch != null) {
      authDispatch({
        type: "SET_AGE_RESTRICTION_ACK",
        payload: true,
      });
    }
  };
  const handleSignout = (): void => {
    fetch(`${findBackend()}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.status === 200) {
          if (authDispatch != null) {
            authDispatch({
              type: "SET_LOGIN",
              payload: null,
            });
            authDispatch({
              type: "SET_ASSOCIATIONS",
              payload: {
                favorites: undefined,
                bookmarks: undefined,
                hidden: undefined,
              },
            });
          }
        } else {
          console.log(await response.text());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const pickBanner = (): string => {
    const isSignedIn = authContext.userName !== null;
    const eligibleBanners = banners.filter((banner) => !(isSignedIn && !banner.show_to_signed_up));
    const numBanners = eligibleBanners.length;
    const bannerIndex = Math.floor(Math.random() * numBanners * 3);
    if (bannerIndex >= numBanners) {
      // We only show banner in 1 in 3 renders.
      setShowBanner(false);
      return "";
    }
    return eligibleBanners[bannerIndex].message;
  };

  const [tabIndex, setTabIndex] = React.useState(0);
  return authContext.ageRestrictionAck ? (
    <nav className="NavBar">
      {authContext.userName === null && (
        <Button className="Login" onClick={handleOpen}>
          Sign In
        </Button>
      )}
      {authContext.userName !== null && (
        <Box>
          <Typography>{authContext.userName}</Typography>
          <Button onClick={handleSignout}>Sign out</Button>
        </Box>
      )}
      <Typography variant="h2" className="Title">
        Escorts
      </Typography>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showBanner}
        autoHideDuration={5000}
        onClose={() => {
          setShowBanner(false);
        }}
        key={"top" + "center"}
      >
        <Alert
          onClose={() => {
            setShowBanner(false);
          }}
          severity="info"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <div dangerouslySetInnerHTML={{ __html: pickBanner() }}></div>
        </Alert>
      </Snackbar>
      <Link href="https://t.me/+Gc3-Sk5a5qBhYjFh">Telegram Group</Link>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Sign In" />
              <Tab label="Sign up" />
            </Tabs>
          </Box>
          <AuthTabPanel value={tabIndex} index={0}>
            <SignIn onSuccess={handleLoginSuccess} />
          </AuthTabPanel>
          <AuthTabPanel value={tabIndex} index={1}>
            <SignUp onSuccess={handleLoginSuccess} />
          </AuthTabPanel>
        </Box>
      </Modal>
    </nav>
  ) : (
    <Modal open={true} onClose={handleClose}>
      <Box sx={style} display="flex" flexDirection="column">
        <Typography variant="h6">Age Restricted Content Warning</Typography>
        <Typography variant="body1">
          This website contains age-restricted materials including nudity and potential mention of sexual activity. By
          entering, you affirm that you are at least 18 years of age or the age of majority in the jurisdiction you are
          accessing the website from and you consent to viewing sexually explicit content.
        </Typography>
        <Button onClick={handleAgeRestrictionAck}>Proceed</Button>
      </Box>
    </Modal>
  );
}

export default NavBar;
