import config from "../config.json";

export const findBackend = (): string => {
  const location = window.location.href;
  if (location.includes("oregon")) {
    return config.backend_url.oregon;
  }
  return config.backend_url.washington;
};

export const findImageUrl = (): string => {
  const location = window.location.href;
  if (location.includes("oregon")) {
    return config.image_url.oregon;
  }
  return config.image_url.washington;
};
