import React, { useContext, useEffect, useRef } from "react";
import Carousel from "react-material-ui-carousel";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import "./provider_display.css";
import { type ProviderPost } from "./provider_feed";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CancelIcon from "@mui/icons-material/Cancel";
import ago from "s-ago";
import { AuthContext, AuthDispatchContext } from "../state/auth_context";
import { findBackend } from "../util/find_backend";
import NoteIcon from "@mui/icons-material/Note";
import CheckIcon from "@mui/icons-material/Check";
import SyncIcon from "@mui/icons-material/Sync";

export interface Image {
  url: string;
}

interface ProviderDisplayProps {
  post: ProviderPost;
  signedIn: boolean;
  association?: string;
  show: boolean;
}

const arePostsEqual = (a: ProviderDisplayProps, b: ProviderDisplayProps): boolean => {
  return (
    a.signedIn === b.signedIn && a.association === b.association && a.post.name === b.post.name && a.show === b.show
  );
};

function ProviderDisplay({ post, signedIn, association, show }: ProviderDisplayProps): JSX.Element {
  return (
    <div className="ProviderDisplay" style={{ display: show ? "block" : "none" }}>
      <Item
        urls={post.images}
        name={post.name}
        postDate={post.postDate}
        providerUrl={post.providerUrl}
        postUrl={post.postUrl}
        postTitle={post.postTitle}
        postLocation={post.postLocation}
        signedIn={signedIn}
        association={association}
        providerNotes={post.note}
      />
      <div>
        <Typography
          className="emojiText postTitle"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
          variant="body1"
          onClick={() => window.open(post.postUrl, "_blank", "noopener noreferrer")}
        >
          {post.postTitle}
        </Typography>
      </div>
    </div>
  );
}

interface ItemProps {
  urls: string[];
  name: string;
  postDate: Date;
  providerUrl: string;
  postUrl: string;
  postTitle: string;
  postLocation: string;
  signedIn: boolean;
  association?: string;
  providerNotes: string;
}

function Item({
  urls,
  name,
  postDate,
  providerUrl,
  postUrl,
  postTitle,
  postLocation,
  signedIn,
  association,
  providerNotes,
}: ItemProps): JSX.Element {
  const authDispatch = useContext(AuthDispatchContext);
  const [providerAssociated, setProviderAssociated] = React.useState<string | undefined>(association);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const authContext = useContext(AuthContext);
  const loggedIn = authContext.userName !== null;
  const [showNotes, setShowNotes] = React.useState<boolean>(false);
  const [notesDirty, setNotesDirty] = React.useState<boolean>(false);
  const noteToSave = useRef<string>(providerNotes);

  useEffect(() => {
    if (providerAssociated !== association) {
      setProviderAssociated(association);
    }
  }, [association]);
  const updateAssociation = async (
    handle: string,
    oldAssociation: string | undefined,
    newAssociation: string,
  ): Promise<void> => {
    // Clear previous association.
    if (oldAssociation !== undefined) {
      const url = `${findBackend()}/remove_association`;
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ handle }),
      };
      await fetch(url, requestOptions)
        .then(async (response) => {
          if (response.status === 200) {
            if (authDispatch !== null) {
              authDispatch({
                type: "SET_ASSOCIATIONS",
                payload: (await response.json()).associations,
              });
            }
            return;
          }
          setError(await response.text());
        })
        .catch((error) => {
          setError("Remove Association failed: " + error);
        });
    }
    if (oldAssociation !== newAssociation) {
      const url = `${findBackend()}/add_association`;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ handle, association: newAssociation }),
      };
      fetch(url, requestOptions)
        .then(async (response) => {
          if (response.status === 200) {
            setProviderAssociated(newAssociation);
            if (authDispatch !== null) {
              authDispatch({
                type: "SET_ASSOCIATIONS",
                payload: (await response.json()).associations,
              });
            }
            return;
          }
          setError(await response.text());
        })
        .catch((error) => {
          setError("Add Association failed: " + error);
        });
    } else {
      setProviderAssociated(undefined);
    }
  };

  const enableButtons = signedIn;
  const showAssociationButton = (association: string): boolean => {
    if (providerAssociated === undefined) {
      return true;
    }
    switch (providerAssociated) {
      case "FAVORITE":
        return association === "FAVORITE";
      case "SAVED":
        return true;
      case "HIDDEN":
        return association === "HIDDEN";
    }
    return false;
  };

  const updateProviderNote = async (): Promise<void> => {
    if (noteToSave.current !== undefined) {
      const url = `${findBackend()}/update_provider_note`;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ provider_handle: name, note: noteToSave.current }),
      };
      fetch(url, requestOptions)
        .then(async (response) => {
          if (response.status === 200) {
            setNotesDirty(false);
          } else {
            void response.text().then((text) => {
              setError(text);
            });
            void onProviderNoteChanged(noteToSave.current);
          }
        })
        .catch((error) => {
          setError("Update Provider Notes failed: " + error);
          void onProviderNoteChanged(noteToSave.current);
        });
    }
  };
  const onProviderNoteChanged = async (notes: string): Promise<void> => {
    noteToSave.current = notes;
    if (!notesDirty) {
      setNotesDirty(true);
      // Call backend api to update the note in 5 seconds.
      setTimeout(() => {
        void updateProviderNote();
      }, 5000);
    }
  };

  console.log("Rendering item for: ", name);
  return error !== undefined ? (
    <Dialog open={error !== undefined}>
      <DialogTitle id="alert-dialog-title">{"Error saving provider"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{error}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setError(undefined);
          }}
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <div>
      <div
        className="carouselCaptionContainer"
        onClick={() => window.open(providerUrl, "_blank", "noopener noreferrer")}
      >
        <span className="carouselCaption">{name}</span>
        <span className="carouselAge">{"• " + ago(postDate)} </span>
        <span className="carouselAge">{"• " + postLocation} </span>
      </div>
      <Carousel
        autoPlay={false}
        indicators={true}
        indicatorContainerProps={{ style: { top: "16px", position: "absolute", zIndex: 1 } }}
      >
        {urls.map((url, i) => (
          <Paper className="Item" key={i}>
            <img src={url} alt={`${name}: escort in Washington state`} className="carouselImage" />
          </Paper>
        ))}
      </Carousel>

      <Box>
        <div>
          {showAssociationButton("FAVORITE") && (
            <Tooltip title="Favorite" placement="top">
              <span>
                <IconButton
                  disabled={!enableButtons}
                  color={providerAssociated === "FAVORITE" ? "success" : "primary"}
                  onClick={() => {
                    void updateAssociation(name, providerAssociated, "FAVORITE");
                  }}
                >
                  <FavoriteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {showAssociationButton("SAVED") && (
            <Tooltip title="Bookmark" placement="top">
              <span>
                <IconButton
                  disabled={!enableButtons}
                  color={providerAssociated === "SAVED" ? "success" : "primary"}
                  onClick={() => {
                    void updateAssociation(name, providerAssociated, "SAVED");
                  }}
                >
                  <BookmarkIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {showAssociationButton("HIDDEN") && (
            <Tooltip title="Hide" placement="top">
              <span>
                <IconButton
                  disabled={!enableButtons}
                  color={providerAssociated === "HIDDEN" ? "success" : "primary"}
                  onClick={() => {
                    void updateAssociation(name, providerAssociated, "HIDDEN");
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {loggedIn && (
            <Tooltip title="Your Notes" placement="top">
              <span>
                <IconButton
                  color={noteToSave.current === null || noteToSave.current.length === 0 ? "primary" : "success"}
                  onClick={() => {
                    setShowNotes(!showNotes);
                  }}
                >
                  <NoteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </div>
        {showNotes && (
          <div>
            <TextField
              label="Notes"
              variant="outlined"
              inputProps={{
                maxlength: 1024,
              }}
              multiline={true}
              fullWidth={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!notesDirty ? <CheckIcon color="success" /> : <SyncIcon color="warning" />}
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                void onProviderNoteChanged(event.target.value);
              }}
              defaultValue={noteToSave.current ?? ""}
            />
          </div>
        )}
      </Box>
    </div>
  );
}

export default React.memo(ProviderDisplay, arePostsEqual);
