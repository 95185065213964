// React context to provide the value of the age restriction notice ack status to all children components.

import React from "react";

export interface AuthState {
  userName: string | null;
  ageRestrictionAck: boolean;
  favorites?: string[];
  bookmarks?: string[];
  hidden?: string[];
}

export const AuthContext = React.createContext<AuthState>({
  userName: null,
  ageRestrictionAck: false,
});

interface IAgeAckAction {
  type: "SET_AGE_RESTRICTION_ACK";
  payload: boolean;
}

interface ISignInAction {
  type: "SET_LOGIN";
  payload: string | null;
}

interface IAssociateAction {
  type: "SET_ASSOCIATIONS";
  payload: {
    favorites?: string[];
    bookmarks?: string[];
    hidden?: string[];
  };
}

type IAuthAction = IAgeAckAction | ISignInAction | IAssociateAction;
export const AGE_RESTRICTION_STORAGE_KEY = "ageRestrictionAck";

export const authReducer = (state: AuthState, action: IAuthAction): AuthState => {
  switch (action.type) {
    case "SET_LOGIN":
      return {
        ...state,
        userName: action.payload,
      };
    case "SET_AGE_RESTRICTION_ACK":
      localStorage.setItem(AGE_RESTRICTION_STORAGE_KEY, action.payload.toString());
      return {
        ...state,
        ageRestrictionAck: action.payload,
      };
    case "SET_ASSOCIATIONS":
      return {
        ...state,
        favorites: action.payload.favorites,
        bookmarks: action.payload.bookmarks,
        hidden: action.payload.hidden,
      };
  }
};
export const AuthDispatchContext = React.createContext<React.Dispatch<IAuthAction> | null>(null);
