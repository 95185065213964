import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { findBackend } from "../util/find_backend";

interface SignUpFormProps {
  onSuccess: (username: string) => void;
}

export default function SignUp({ onSuccess }: SignUpFormProps): JSX.Element {
  const [error, setError] = React.useState<string | null>(null);
  const [passwordError, setPasswordError] = React.useState<string | null>(null);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setError(null);
    const data = new FormData(event.currentTarget);
    const username = data.get("username");
    const password = data.get("password");
    if (username instanceof File || username === null || username.trim() === "") {
      setError("Username is required");
      return;
    }
    if (password instanceof File || password === null || password.trim() === "") {
      setError("Password is required");
      return;
    }

    fetch(`${findBackend()}/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then(async (response) => {
        // handle response
        if (response.status === 200) {
          onSuccess((await response.json()).username);
          return;
        }
        setError(await response.text());
      })
      .catch((error) => {
        // handle error
        setError("Sign up failed: " + error);
      });
  };

  const validatePassword = (password: string): string | null => {
    const errorMessages: string[] = [];

    // Check password length
    if (password.length < 12 || password.length > 32) {
      errorMessages.push("Password must be between 12 and 32 characters");
    }

    // Check for uppercase letter
    if (!/[A-Z]/.test(password)) {
      errorMessages.push("Password must contain at least one uppercase letter");
    }

    // Check for lowercase letter
    if (!/[a-z]/.test(password)) {
      errorMessages.push("Password must contain at least one lowercase letter");
    }

    // Check for digit
    if (!/\d/.test(password)) {
      errorMessages.push("Password must contain at least one digit");
    }

    // Check for special symbol
    if (!/[@#$%]/.test(password)) {
      errorMessages.push("Password must contain at least one special symbol (@, #, $, %)");
    }

    // Return error message if any
    if (errorMessages.length > 0) {
      return errorMessages.join(", ");
    }

    // Password is valid, return null
    return null;
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField required fullWidth label="Username" name="username" autoFocus />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Password"
                type="password"
                name="password"
                autoComplete="new-password"
                error={passwordError != null}
                helperText={passwordError}
                onChange={(event) => {
                  const password = event.target.value;
                  setPasswordError(validatePassword(password));
                }}
              />
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={passwordError != null}>
            Sign Up
          </Button>
          {error != null && <Typography color="error">{error}</Typography>}
        </Box>
      </Box>
    </Container>
  );
}
