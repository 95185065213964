import React, { useContext, useState } from "react";
import ProviderDisplay from "./provider_display";
import { z } from "zod";
import { useInfiniteQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroller";
import { AuthContext } from "../state/auth_context";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { findBackend, findImageUrl } from "../util/find_backend";

const ProviderPostSchema = z.object({
  name: z.string(),
  images: z.array(
    z.string().transform((url: string) => {
      return url.includes("static") ? `${findImageUrl()}/${url}` : url;
    }),
  ),
  postDate: z.number().transform((date) => new Date(date * 1000)),
  providerUrl: z.string(),
  postUrl: z.string(),
  postTitle: z.string(),
  postLocation: z.string(),
  note: z.string(),
});

export type ProviderPost = z.infer<typeof ProviderPostSchema>;

interface FilterTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function FilterTabPanel(props: FilterTabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <div style={{ display: value !== index ? "none" : "block" }} {...other}>
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

function ProviderFilterPanel(): JSX.Element {
  const [tabIndex, setTabIndex] = React.useState(0);
  const authContext = useContext(AuthContext);
  const loggedIn = authContext.userName !== null;
  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setTabIndex(newValue);
  };
  return authContext.ageRestrictionAck ? (
    <Box>
      <Box
        style={{
          position: "sticky",
          top: "64px",
          zIndex: 100,
          opacity: 1,
          backgroundColor: "#f5f5f5",
          display: "flex",
        }}
      >
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="All Posts" />
          <Tab label="Favorites" disabled={!loggedIn} />
          <Tab label="Bookmarks" disabled={!loggedIn} />
          <Tab label="Hidden" disabled={!loggedIn} />
        </Tabs>
      </Box>
      <Box>
        <FilterTabPanel value={tabIndex} index={0}>
          <ProviderFeed />
        </FilterTabPanel>
        <FilterTabPanel value={tabIndex} index={1}>
          <ProviderFeed association={"FAVORITE"} />
        </FilterTabPanel>
        <FilterTabPanel value={tabIndex} index={2}>
          <ProviderFeed association={"SAVED"} />
        </FilterTabPanel>
        <FilterTabPanel value={tabIndex} index={3}>
          <ProviderFeed association={"HIDDEN"} />
        </FilterTabPanel>
      </Box>
    </Box>
  ) : (
    <div></div>
  );
}

interface ProviderFeedProps {
  association?: string;
}

function ProviderFeed({ association }: ProviderFeedProps): JSX.Element {
  const authState = useContext(AuthContext);
  const [snapshotId, setSnapshotId] = useState<number | undefined>(undefined);
  const fetchPosts = async ({ pageParam = 1 }): Promise<{ results: ProviderPost[]; nextPage: number }> => {
    const params: any = {};
    params.page = pageParam;
    if (association !== undefined) {
      params.associations = association;
    }
    if (snapshotId !== undefined) {
      params.snapshotId = snapshotId;
    }
    const searchParams = new URLSearchParams(params);
    const res = await fetch(`${findBackend()}/posts?${searchParams.toString()}`);
    const json = await res.json();
    const results = z.array(ProviderPostSchema).parse(json.posts);
    if (typeof json.snapshotId === "number") {
      setSnapshotId(json.snapshotId);
    }
    return { results, nextPage: pageParam + 1 };
  };

  const findAssociation = (name: string): string | undefined => {
    if (authState.userName === null) {
      return undefined;
    }
    const associations = {
      FAVORITE: authState.favorites ?? [],
      SAVED: authState.bookmarks ?? [],
      HIDDEN: authState.hidden ?? [],
    };
    for (const [k, v] of Object.entries(associations)) {
      if (v.includes(name)) {
        return k;
      }
    }
    return undefined;
  };
  const showProvider = (post: ProviderPost): boolean => {
    switch (association) {
      case "FAVORITE":
        return (authState.favorites ?? []).includes(post.name);
      case "SAVED":
        return (authState.bookmarks ?? []).includes(post.name);
      case "HIDDEN":
        return (authState.hidden ?? []).includes(post.name);
      default:
        return !(authState.hidden ?? []).includes(post.name);
    }
  };

  const queryKey = ["posts", JSON.stringify(association)];
  if (association !== undefined) {
    switch (association) {
      case "FAVORITE":
        queryKey.push(JSON.stringify(authState.favorites));
        break;
      case "SAVED":
        queryKey.push(JSON.stringify(authState.bookmarks));
        break;
      case "HIDDEN":
        queryKey.push(JSON.stringify(authState.hidden));
        break;
      default:
        break;
    }
  }
  const { data, isLoading, isError, hasNextPage, fetchNextPage } = useInfiniteQuery(queryKey, fetchPosts, {
    getNextPageParam: (lastPage, pages) => (lastPage.results.length !== 0 ? lastPage.nextPage : undefined),
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return !authState.ageRestrictionAck ? (
    <Typography variant="h3" sx={{ marginTop: "60px" }}>
      Must ack age restriction to see content
    </Typography>
  ) : isLoading ? (
    <p>Loading...</p>
  ) : isError || data === undefined ? (
    <p>Error</p>
  ) : (
    <Box>
      <InfiniteScroll hasMore={hasNextPage} loadMore={fetchNextPage as any}>
        {data.pages.map((page) =>
          page.results.map((post, i) => (
            <ProviderDisplay
              key={post.name}
              post={post}
              association={findAssociation(post.name)}
              signedIn={authState.userName !== null}
              show={showProvider(post)}
            />
          )),
        )}
      </InfiniteScroll>
    </Box>
  );
}

export default ProviderFilterPanel;
