import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Checkbox, FormControlLabel } from "@mui/material";
import { findBackend } from "../util/find_backend";

interface SignInFormProps {
  onSuccess: (username: string) => void;
}

export default function SignIn({ onSuccess }: SignInFormProps): JSX.Element {
  const [error, setError] = React.useState<string | null>(null);
  const [rememberMe, setRememberMe] = React.useState(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setError(null);
    const data = new FormData(event.currentTarget);
    const username = data.get("username");
    const password = data.get("password");
    if (username instanceof File || username === null || username.trim() === "") {
      setError("Username is required");
      return;
    }
    if (password instanceof File || password === null || password.trim() === "") {
      setError("Password is required");
      return;
    }

    fetch(`${findBackend()}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
        remember: rememberMe,
      }),
    })
      .then(async (response) => {
        // handle response
        if (response.status === 200) {
          onSuccess((await response.json()).username);
          return;
        }
        setError(await response.text());
      })
      .catch((error) => {
        // handle error
        setError("Sign in failed: " + error);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField margin="normal" required fullWidth label="Username" name="username" autoFocus />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            name="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                onChange={(event) => {
                  setRememberMe(event.target.checked);
                }}
              />
            }
            label="Remember me"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
          {error != null && <Typography color="error">{error}</Typography>}
        </Box>
      </Box>
    </Container>
  );
}
