import React, { useEffect } from "react";
import "./App.css";
import ProviderFilterPanel from "./components/provider_feed";
import NavBar from "./components/nav_bar";
import { authReducer, AuthContext, AuthDispatchContext, AGE_RESTRICTION_STORAGE_KEY } from "./state/auth_context";
import { isbot } from "isbot";
import { findBackend } from "./util/find_backend";

function App(): JSX.Element {
  const [authState, authDispatch] = React.useReducer(authReducer, {
    userName: null,
    ageRestrictionAck: false,
  });
  useEffect(() => {
    const ageAck = isbot(navigator.userAgent) ? true : localStorage.getItem(AGE_RESTRICTION_STORAGE_KEY);
    authDispatch({
      type: "SET_AGE_RESTRICTION_ACK",
      payload: (ageAck ?? "false") !== "false",
    });
    fetch(`${findBackend()}/profile`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (profileResp) => {
        let userName: string | null = null;
        if (profileResp.status === 200) {
          const respJson = await profileResp.json();
          userName = respJson.username;
          if (userName !== null) {
            authDispatch({
              type: "SET_LOGIN",
              payload: userName,
            });
          }
          if (respJson.associations != null) {
            authDispatch({
              type: "SET_ASSOCIATIONS",
              payload: respJson.associations,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="App">
      <AuthContext.Provider value={authState}>
        <AuthDispatchContext.Provider value={authDispatch}>
          <NavBar />
          <div>
            <ProviderFilterPanel />
          </div>
        </AuthDispatchContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
